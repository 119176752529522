var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wordList"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.handleList,"height":_vm.pagingMode==='滚动'?600:null,"cell-class-name":"cellClassName"}},[_c('el-table-column',{attrs:{"prop":"word","width":"220"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-custom"},[_c('span',{staticClass:"header-custom__label"},[_vm._v("单词")]),_c('img',{staticClass:"header-custom__view",attrs:{"src":_vm.isShowWords?_vm.display:_vm.notDisplayed,"alt":"眼睛"},on:{"click":function($event){return _vm.toggleDisplay('isShowWords')}}})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(!row.isShowWords)?_c('el-button',{staticClass:"header-display",attrs:{"size":"small"},on:{"click":function($event){return _vm.rowShowWord($index)}}},[_vm._v(" 显示单词 ")]):_c('el-tooltip',{staticClass:"item",attrs:{"content":row.word,"disabled":row.word.length < 25,"effect":"dark","placement":"top"}},[_c('div',{staticClass:"header-font"},[_vm._v(_vm._s(row.word.length > 25 ? row.word.substring(0, 25) + '...' : row.word))])])]}}])}),_c('el-table-column',{attrs:{"prop":"americanPronunciation"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-custom"},[_c('span',{staticClass:"header-custom__label"},[_vm._v("音标")]),_c('span',{staticClass:"pronunciation",on:{"click":_vm.switchPronunciation}},[_vm._v(_vm._s(_vm.pronunciation === 0 ? '美' : '英'))])])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"header-custom"},[_c('div',{staticClass:"header-font"},[_vm._v(_vm._s(_vm.pronunciation === 0 ? row.americanPronunciation : row.britishPronunciation)+" ")]),(row.voiceLoading)?_c('i',{staticClass:"el-icon-loading",staticStyle:{"margin-left":"10px"}}):_c('img',{staticClass:"header-custom__horn",attrs:{"alt":"喇叭","src":require("@/assets/images/shengyin_shiti.png")},on:{"click":function($event){return _vm.wordPronunciation(row)}}})])]}}])}),_c('el-table-column',{attrs:{"prop":"interpretation","width":"320"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-custom"},[_c('span',{staticClass:"header-custom__label"},[_vm._v("释义")]),_c('img',{staticClass:"header-custom__view",attrs:{"src":_vm.isShowInterpretation?_vm.display:_vm.notDisplayed,"alt":"眼睛"},on:{"click":function($event){return _vm.toggleDisplay('isShowInterpretation')}}})])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(!row.isShowInterpretation)?_c('el-button',{staticClass:"header-display",attrs:{"size":"small"},on:{"click":function($event){row.isShowInterpretation = true}}},[_vm._v(" 显示释义 ")]):_c('div',{staticClass:"header-custom"},[_c('el-tooltip',{staticClass:"item",attrs:{"content":row.interpretation,"disabled":row.interpretation.length < 15,"effect":"dark","placement":"top"}},[_c('div',{staticClass:"header-font"},[_vm._v(" "+_vm._s(row.interpretation.length > 15 ? row.interpretation.substring(0, 15) + '...' : row.interpretation)+" ")])]),(!_vm.isImitation)?_c('img',{staticClass:"header-custom__results",attrs:{"src":row.correctFlag === 1 ? _vm.correct : _vm.wrong,"alt":"判断"}}):_vm._e()],1)]}}])}),(_vm.isImitation)?_c('el-table-column',{attrs:{"prop":"imitation","width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-custom"},[_c('span',{staticClass:"header-custom__label"},[_vm._v("临摹单词")])])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('div',{staticClass:"header-custom"},[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入内容"},model:{value:(_vm.tableData[(_vm.pageNum * _vm.pageSize) - _vm.pageSize + $index].imitation),callback:function ($$v) {_vm.$set(_vm.tableData[(_vm.pageNum * _vm.pageSize) - _vm.pageSize + $index], "imitation", $$v)},expression:"tableData[(pageNum * pageSize) - pageSize + $index].imitation"}}),(row.imitation)?_c('img',{staticClass:"header-custom__results",attrs:{"src":row.imitation === row.word ? _vm.correct : _vm.wrong,"alt":"判断"}}):_vm._e()],1)]}}],null,false,3596007088)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"isNeologism","width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-custom"},[_c('span',{staticClass:"header-custom__label"},[_vm._v("加入生词本")])])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-switch',{attrs:{"active-value":1,"inactive-value":0,"active-color":"#358BFD","inactive-color":"#EAEAEA"},on:{"change":function($event){return _vm.addWordBook(row)}},model:{value:(_vm.tableData[(_vm.pageNum * _vm.pageSize) - _vm.pageSize + $index].isNeologism),callback:function ($$v) {_vm.$set(_vm.tableData[(_vm.pageNum * _vm.pageSize) - _vm.pageSize + $index], "isNeologism", $$v)},expression:"tableData[(pageNum * pageSize) - pageSize + $index].isNeologism"}})]}}])})],1),(_vm.pagingMode === '按钮')?_c('el-pagination',{attrs:{"current-page":_vm.pageNum,"total":_vm.total,"background":"","layout":"prev, pager, next"},on:{"update:currentPage":function($event){_vm.pageNum=$event},"update:current-page":function($event){_vm.pageNum=$event}}}):_vm._e(),_c('audio',{ref:"audio",attrs:{"src":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }