<template>
  <div>
    <div class="progress">
      <div class="progress-head">
        <span class="title">学习进度</span>
        <el-progress
            :percentage="learningProgress"
            :show-text="false"
            :stroke-width="8"
            style="width: 457px; margin: 0 8px"
        />
        <span style="color:#4392FD;">{{ wordInfo.testWordNum || 0 }}</span>
        <span>/{{ allWordNum }}，</span>
        <span>已完成</span>
        <span style="color:#4392FD;">{{ learningProgress }}%</span>
        <el-button size="mini" @click="continueLearn">{{ wordInfo.lastStudyNum ? '继续练习' : '立即练习' }}</el-button>
      </div>

      <div class="progress-rules">
        <img alt="" src="@/assets/images/tishishuoming.png">
        <span>学习进度计算规则：测试过并答对的单词为已完成。</span>
      </div>

      <div id="progressList" class="progress-list">
        <div v-for="(i,index) in classHours" :key="index" class="progress-list--item" @click="handleClick(index+1)">
          <div class="progress-list--item__head">
            <div class="title">第{{ index + 1 }}课</div>
            <div class="words">{{ i }}个单词</div>
            <div class="isFinish">
              <img :src="isItComplete(index + 1)" alt="完成标识">
            </div>
          </div>
          <div class="progress-list--item__footer">
            <el-button size="small" type="primary" @click.stop="testNow(index + 1)">立即测试</el-button>
          </div>
        </div>
      </div>

      <div class="progress-up" @click="listTop">
        <i class="el-icon-upload2 progress-up__icon"></i>
        <p>置顶</p>
      </div>

    </div>

    <Dialog
        key="dialog" :showClose="false" :escClose="true" :title="dialog.title" :visible.sync="dialog.open" top="90px" width="738px">
      <template #title>
        <div class="word-test__head">
          <div class="test-head__title">{{ dialog.title }}</div>
          <div class="test-head__right">
            <div class="timeCost">用时：{{ testMinute }}分{{ testSecond }}秒</div>
            <div class="score">得分：{{ testScore }}分</div>
            <div class="close-icon"><i class="el-icon-close" @click="handleCloseDialog" /></div>
          </div>
        </div>
      </template>

      <div v-if="testList.length && dialog.open" class="word-test">
        <div class="word-test__title">
          <span class="current">{{ activeTest + 1 }}</span>
          <span>/{{ testList.length }}</span>
        </div>
        <div class="word-test__paraphrase">
          释义：{{ testList[activeTest].interpretation }}
        </div>
        <div class="word-test__input">
          <el-input
              v-model="enterWord"
              :readonly="isAnswer"
              clearable
              placeholder="请输入正确的单词"
              @keyup.enter.native="next"
          />
          <img v-if="isAnswer" :src="judgmentPicture?correct:wrong" alt="判断">
        </div>
        <div :style="{visibility:isAnswer?'visible':'hidden'}" class="word-test__answer">
          {{ testList[activeTest].word }}
        </div>
        <div class="word-test__btn">
          <el-button :disabled="!enterWord"
                     size="small"
                     type="primary"
                     @click="next">{{ btnText }}
          </el-button>
        </div>
      </div>
    </Dialog>

    <Dialog
        key="result"
        :title="result.title"
        :visible.sync="result.open"
        top="20px"
        width="900px"
        @close="resultsClose"
    >
      <div class="test-results">
        <div class="test-results__title">测试结果</div>
        <div v-if="testResults" class="test-results__info">
          <div class="test-results__item">
            <img alt="" class="test-results__item-img" src="@/assets/images/shuyi_zhengque-wancheng.png">
            <span>答对:</span>
            <span class="test-results__item-num">{{ testResults.rightNum }}</span>
            <span class="test-results__item-unit">题</span>
          </div>
          <div class="test-results__item">
            <img alt="" class="test-results__item-img" src="@/assets/images/cuowu.png">
            <span>答错:</span>
            <span class="test-results__item-num">{{ testResults.errorNum }}</span>
            <span class="test-results__item-unit">题</span>
          </div>
          <div class="test-results__item">
            <img alt="" class="test-results__item-img" src="@/assets/images/zhanbi.png">
            <span>正确率:</span>
            <span class="test-results__item-num">{{ testResults.accuracy }}</span>
            <span class="test-results__item-unit">%</span>
          </div>
          <div class="test-results__item">
            <img alt="" class="test-results__item-img" src="@/assets/images/cz-dftj.png">
            <span>得分:</span>
            <span class="test-results__item-num">{{ testResults.score }}</span>
            <span class="test-results__item-unit">分</span>
          </div>
        </div>
        <WordList v-if="testResults" :is-imitation="false" :list="testResults.wordList" :total="10" paging-mode="滚动"/>
      </div>
    </Dialog>

  </div>
</template>

<script>

import Dialog from '@/views/basicSkills/wordExercise/components/Dialog.vue'
import {addRecordApi, recordPracticeApi, testRecordApi, wordListApi} from '@/api/basicSkills/wordExercise'
import WordList from '@/views/basicSkills/wordExercise/components/WordList.vue'
import {mapState} from 'vuex'

export default {
  name: 'WordExerciseProgress',
  components: {
    Dialog,
    WordList
  },
  data() {
    return {
      result: {
        open: false,
        title: ''
      },
      dialog: {
        open: false,
        title: ''
      },
      setTop: require('@/assets/images/set-top.png'),
      acSetTop: require('@/assets/images/ac-set-top.png'),
      correct: require('@/assets/images/correct.png'),
      wrong: require('@/assets/images/wrong.png'),
      judgmentPicture: false, // 输入的单词是否正确
      isAnswer: false, // 是否显示答案
      finish: require('@/assets/images/finish.png'),
      incomplete: require('@/assets/images/incomplete.png'),
      allWordNum: 0, // 单词总数
      enterWord: '', // 输入单词
      btnText: '提交',
      wordInfo: {},
      testList: [],
      activeTest: 0, // 当前测试单词
      wordList: [], // 单词答案列表
      currentCourse: null, // 当前测试课程
      isSubmit: false,
      testResults: null,// 测试结果
      testMinute: 0,// 测试计时分
      testSecond: 0,// 测试计时秒
      timer: null,// 计时器
    }
  },
  watch: {
    'dialog.open'(val) {
      if (val) {
        this.testList = []
        this.wordList = []
        this.activeTest = 0
        this.isAnswer = false
        this.enterWord = ''
        this.btnText = '提交'
      }
    },
    viewTestResults(bol) {
      this.handleTestResult(bol)
    }
  },
  computed: {
    classHours() {
      const WORDS_NUMBER = 20
      const WORDS_TOTAL = this.allWordNum
      if (WORDS_TOTAL === 0 || !WORDS_TOTAL) return []
      const remainder = WORDS_TOTAL % WORDS_NUMBER
      const number = WORDS_TOTAL / WORDS_NUMBER
      const list = new Array(Math.ceil(number)).fill(WORDS_NUMBER)
      remainder && (list[list?.length - 1] = remainder)
      return list
    },
    learningProgress() {
      const PASSED = this.wordInfo.testWordNum || 0
      const WORDS_TOTAL = this.allWordNum || 0
      return PASSED === 0 ? 0 : Number((PASSED / WORDS_TOTAL * 100).toFixed(2))
    },
    ...mapState({
      viewTestResults: state => state.wordExercise.viewTestResults,
      testResultsItem: state => state.wordExercise.testResultsItem
    }),
    testScore() {
      return this.wordList.filter(i => i.correctFlag === 1).length || 0
    }
  },
  created() {
    this.getList()
  },
  methods: {
    listTop() {
      document.getElementById('progressList').scrollTop = 0
    },
    startTimer() {
      // 先清空时间
      clearInterval(this.timer)
      this.testSecond = 0
      this.testMinute = 0
      this.timer = setInterval(() => {
        if (this.testSecond >= 60) {
          this.testSecond = 0
          this.testMinute += 1
        }
        this.testSecond += 1
      }, 1000)
    },
    endTimer() {
      clearInterval(this.timer);
    },
    getList() {
      wordListApi().then(res => {
        this.allWordNum = res.data.allWordNum
        this.wordInfo = res.data
      })
    },
    /* 结果关闭 */
    resultsClose() {
      this.$store.commit('SET_VIEW_TEST_RESULTS', {
        open: false,
        item: this.testResultsItem
      })
    },
    /* 立即测试 */
    testNow(num) {
      this.isSubmit = false
      this.currentCourse = num
      this.dialog.open = true
      this.startTimer()
      this.dialog.title = `第${num}课测试`
      recordPracticeApi({
        getModel: 1,
        num
      }).then(res => {
        this.testList = res.data
      })
    },
    /* 去学习 */
    handleClick(num) {
      this.$router.push({
        path: '/wordExerciseDetails',
        query: {name: `第${num}课`, course: num, totalCourse: this.classHours.length}
      })
    },
    isItComplete(num) {
      const testNumList = this.wordInfo?.testNumList
      if (testNumList) {
        return testNumList.includes(num) ? this.finish : this.incomplete
      } else {
        return this.incomplete
      }
    },
    /* 继续学习 */
    continueLearn() {
      const num = this.wordInfo.lastStudyNum ?? 1
      this.handleClick(num)
    },
    /* 测试结果详情 */
    testResultDetails(resultId) {
      testRecordApi(resultId).then(r => {
        this.testResults = r.data
      })
    },
    handleTestResult(bol, data = null) {
      const item = data || this.testResultsItem
      if (bol) {
        this.testResultDetails(item.id)
      }
      this.result.open = bol
      this.result.title = `第${item.num}节课测试`
    },
    next() {
      if (!this.enterWord) return this.$message.warning('请输入单词，再进行提交')
      if (this.btnText === '提交') {
        const rightOrWrong = this.enterWord === this.testList[this.activeTest].word
        this.judgmentPicture = rightOrWrong
        this.isAnswer = true
        this.wordList.push({
          correctFlag: rightOrWrong ? 1 : 0,
          id: this.testList[this.activeTest].id
        })
        if (this.activeTest === (this.testList.length - 1) && !this.isSubmit) {
          this.isSubmit = true
          // const correct = this.wordList.filter(i => i.correctFlag === 1).length || 0
          const correct = this.testScore
          const data = {
            num: this.currentCourse,
            wordList: this.wordList,
            accuracy: correct / 20 * 100,
            score: correct
          }
          addRecordApi(data).then(res => {
            this.dialog.open = false
            this.getList()
            this.endTimer()
            this.handleTestResult(true, res.data)
          })
        } else {
          this.btnText = '下一题'
        }
      } else {
        this.activeTest += 1
        this.isAnswer = false
        this.enterWord = ''
        this.btnText = '提交'
      }
    },
    /* 点击icon管理弹框 */
    handleCloseDialog() {
      this.dialog.open = false
    }
  }
}
</script>

<style lang="less" scoped>
.progress {
  padding: 30px 10px 30px 0;
  position: relative;

  &-head {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    padding: 0 30px;

    .title {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
    }

    .el-button {
      color: #4392FD;
      border-color: #4392FD;
      background-color: #fff;
      margin-left: 8px;
    }
  }

  &-rules {
    height: 40px;
    background: linear-gradient(125deg, #F6FAFF 0%, #E5F4FB 100%);
    box-shadow: 0 8px 10px 0 rgba(40, 113, 234, 0.1);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #727272;
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin: 20px 20px 20px 30px;

    img {
      margin-right: 10px;
    }
  }

  &-list {
    height: 504px;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-top: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }

    /* 滚动条滑块 */

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: #E6E6E6;
      -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
    }

    &--item {
      width: 211px;
      height: 150px;
      background: #FFFFFF;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      margin-left: 20px;
      margin-bottom: 20px;
      cursor: pointer;

      &__head {
        position: relative;
        padding: 20px 0;

        .title {
          text-align: center;
          padding-bottom: 10px;
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #4392FD;
          letter-spacing: 5px;
        }

        .words {
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #555555;
        }

        .isFinish {
          width: 36px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &__footer {
        border-top: 1px solid #F4F4F4;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &-up {
    position: absolute;
    right: 30px;
    bottom: 20px;
    width: 50px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    cursor: pointer;

    &:hover {
      color: #4392FD;
    }

    &__icon {
      font-size: 18px;
    }
  }
}

.word-test {
  width: 517px;
  height: 539px;
  margin: 53px auto 36px;
  background-image: url('../../../assets/images/calendar.png');
  background-repeat: no-repeat;
  background-position: 50%;

  &__title {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
    padding-top: 71px;

    .current {
      color: #FFCA08;
    }
  }

  &__paraphrase {
    width: 438px;
    margin: 122px auto 36px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    overflow: auto;
    height: 56px;
  }

  &__input {

    display: flex;
    align-items: center;
    justify-content: center;

    ::v-deep .el-input {
      width: 439px;
      height: 56px;
      margin-right: 10px;

      .el-input__inner {
        height: 100%;
        font-size: 18px;
      }
    }

  }

  &__answer {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4C97FD;
    padding: 0 42px;
    margin-top: 10px;
    overflow: auto;
    height: 56px;
  }

  &__btn {
    text-align: center;
    margin: 40px auto 0;

    .el-button {
      width: 70px;
    }
  }
}

.word-test__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;

  .test-head__title {
    font-size: 18px;
    color: #FFFFFF;
  }

  .test-head__right {
    display: flex;
    align-items: center;

    .timeCost,
    .score,
    .close-icon{
      height: 16px;
      line-height: 16px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #DDEBFF;
      padding: 0 10px;
      cursor: default;
    }

    .score, .close-icon {
      border-left: 1px solid #84B5FB;
    }
    .close-icon{
      cursor: pointer;
    }
  }
}

.test-results {
  &__title {
    font-size: 26px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4392FD;
    text-align: center;
    padding: 30px 0;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    padding: 0 20px;
    height: 21px;

    & + & {
      border-left: 1px solid #E7EDF6;
    }

    &-img {
      margin-right: 6px;
    }

    &-num {
      font-size: 26px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #4392FD;
      padding: 0 4px;
    }

    &-unit {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
  }
}

</style>
