<template>
  <div class="wordList">
    <el-table
        :data="handleList"
        :height="pagingMode==='滚动'?600:null"
        cell-class-name="cellClassName"
        style="width: 100%"
    >
      <el-table-column prop="word" width="220">
        <template #header>
          <div class="header-custom">
            <span class="header-custom__label">单词</span>
            <img
                :src="isShowWords?display:notDisplayed"
                alt="眼睛"
                class="header-custom__view"
                @click="toggleDisplay('isShowWords')"
            >
          </div>
        </template>
        <template #default="{row,$index}">
          <el-button
              v-if="!row.isShowWords"
              class="header-display"
              size="small"
              @click="rowShowWord($index)"
          >
            显示单词
          </el-button>

          <el-tooltip
              v-else
              :content="row.word"
              :disabled="row.word.length < 25"
              class="item"
              effect="dark"
              placement="top"
          >
            <div class="header-font">{{ row.word.length > 25 ? row.word.substring(0, 25) + '...' : row.word }}</div>
          </el-tooltip>

        </template>
      </el-table-column>
      <el-table-column prop="americanPronunciation">
        <template #header>
          <div class="header-custom">
            <span class="header-custom__label">音标</span>
            <span class="pronunciation" @click="switchPronunciation">{{ pronunciation === 0 ? '美' : '英' }}</span>
          </div>
        </template>
        <template #default="{row}">
          <div class="header-custom">
            <div class="header-font">{{
                pronunciation === 0 ? row.americanPronunciation : row.britishPronunciation
              }}
            </div>
            <i v-if="row.voiceLoading" class="el-icon-loading" style="margin-left: 10px" />
            <img
                v-else
                alt="喇叭"
                class="header-custom__horn"
                src="@/assets/images/shengyin_shiti.png"
                @click="wordPronunciation(row)"
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="interpretation" width="320">
        <template #header>
          <div class="header-custom">
            <span class="header-custom__label">释义</span>
            <img
                :src="isShowInterpretation?display:notDisplayed"
                alt="眼睛"
                class="header-custom__view"
                @click="toggleDisplay('isShowInterpretation')"
            >
          </div>
        </template>
        <template #default="{row}">
          <el-button
              v-if="!row.isShowInterpretation"
              class="header-display"
              size="small"
              @click="row.isShowInterpretation = true"
          >
            显示释义
          </el-button>
          <div v-else class="header-custom">
            <el-tooltip
                :content="row.interpretation"
                :disabled="row.interpretation.length < 15"
                class="item"
                effect="dark"
                placement="top"
            >
              <div class="header-font">
                {{ row.interpretation.length > 15 ? row.interpretation.substring(0, 15) + '...' : row.interpretation }}
              </div>
            </el-tooltip>
            <img
                v-if="!isImitation"
                :src="row.correctFlag === 1 ? correct : wrong"
                alt="判断"
                class="header-custom__results"
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="isImitation" prop="imitation" width="260">
        <template #header>
          <div class="header-custom">
            <span class="header-custom__label">临摹单词</span>
          </div>
        </template>
        <template #default="{ row, $index }">
          <div class="header-custom">
            <el-input
                v-model="tableData[(pageNum * pageSize) - pageSize + $index].imitation"
                clearable
                placeholder="请输入内容"
            />
            <img
                v-if="row.imitation"
                :src="row.imitation === row.word ? correct : wrong"
                alt="判断"
                class="header-custom__results"
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="isNeologism" width="100">
        <template #header>
          <div class="header-custom">
            <span class="header-custom__label">加入生词本</span>
          </div>
        </template>
        <template #default="{row,$index}">
          <el-switch
              v-model="tableData[(pageNum * pageSize) - pageSize + $index].isNeologism"
              :active-value="1"
              :inactive-value="0"
              active-color="#358BFD"
              inactive-color="#EAEAEA"
              @change="addWordBook(row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        v-if="pagingMode === '按钮'"
        :current-page.sync="pageNum"
        :total="total"
        background
        layout="prev, pager, next"
    />
    <audio ref="audio" src=""/>
  </div>
</template>

<script>

import {addWordApi, removeWordApi, wordPronunciationApi} from '@/api/basicSkills/wordExercise'

export default {
  name: 'WordList',
  props: {
    pagingMode: {
      type: String,
      default: '按钮',
      validator: val => ['按钮', '滚动'].includes(val)
    },
    list: {
      type: Array,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    isImitation: {
      type: Boolean,
      default: true
    }

  },
  data() {
    return {
      display: require('@/assets/images/yanjing-2.png'),
      notDisplayed: require('@/assets/images/yanjing.png'),
      correct: require('@/assets/images/correct.png'),
      wrong: require('@/assets/images/wrong.png'),
      isShowWords: false, // 是否显示单词
      isShowInterpretation: true, // 是否显示释义
      pronunciation: 0, // 0美音 1英音
      tableData: [],
      pronunciationAudio: null,
      pageSize: 10, // 分页个数
      pageNum: 1// 当前分页
    }
  },
  computed: {
    handleList() {
      const deadline = this.pageNum * this.pageSize
      const arr = JSON.parse(JSON.stringify(this.tableData))
      return (this.pagingMode === '滚动' ? arr : arr.splice(deadline - this.pageSize, this.pageSize)).map(item => {
        return {
          ...item,
          voiceLoading: false
        }
      })
    }
  },
  watch: {
    list: {
      immediate: true,
      handler() {
        this.getList()
      }
    }
  },
  methods: {
    getList() {
      this.pageNum = 1
      const arr = JSON.parse(JSON.stringify(this.list))
      this.tableData = arr.map((i) => {
        return {
          ...i,
          isShowWords: i.isShowWords ?? this.isShowWords,
          isShowInterpretation: i.isShowInterpretation ?? this.isShowInterpretation,
          imitation: ''
        }
      })
    },
    /* 美音切换 */
    switchPronunciation() {
      this.pronunciation = this.pronunciation === 0 ? 1 : 0
    },
    /* 行单词切换 */
    rowShowWord(index) {
      const $index = (this.pageNum * this.pageSize) - this.pageSize + index
      this.tableData[$index].isShowWords = true
    },
    /* 切换小眼睛 */
    toggleDisplay(key) {
      this[key] = !this[key]
      this.handleList.forEach(i => {
        i[key] = this[key]
      })
      this.tableData.forEach(i => {
        i[key] = this[key]
      })
    },
    /* 加入单词本 */
    addWordBook(row) {
      if (row.isNeologism === 1) {
        removeWordApi(row.id)
      } else {
        addWordApi(row.id)
      }
    },
    /* 单词发音 */
    wordPronunciation(row) {
      row.voiceLoading = true
      wordPronunciationApi({
        type: this.pronunciation,
        audio: row.word
      }).then(res => {
        const audio = this.$refs.audio
        audio.src = window.URL.createObjectURL(res)
        audio.volume = 1
        audio.play()
      }).finally(() => {
        row.voiceLoading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.wordList {
  box-sizing: border-box;
  height: 100%;
  padding: 20px 30px;

  ::v-deep .el-table {
    &__header {
      th {
        background-color: #EDF1F7;
      }
    }

    .header-custom {
      display: flex;
      align-items: center;

      &__label {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }

      &__view {
        margin-left: 4px;
        cursor: pointer;
      }

      &__horn {
        margin-left: 10px;
        cursor: pointer;
      }

      &__results {
        margin-left: 10px;
      }

      .pronunciation {
        width: 22px;
        height: 18px;
        border: 1px solid #358BFD;
        margin-left: 4px;
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #358BFD;
        line-height: 18px;
        cursor: pointer;
      }
    }

    .header-display {
      border-color: #358BFD;
      color: #358BFD;

      &:hover {
        background-color: #FFF;
      }
    }

    .header-font {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .cellClassName {
      border-bottom: none;
    }
  }

  .el-pagination {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
