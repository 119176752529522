<template>
  <el-dialog
      v-if="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="escClose"
      :show-close="showClose"
      :title="title"
      :top="top"
      :visible.sync="dialogVisible"
      :width="width"
      center
      custom-class="we-dialog"
  >
    <template v-if="$slots.title" #title>
      <slot name="title"></slot>
    </template>
    <div>
      <slot/>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="handleClose">关 闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: true
    },
    escClose: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    width: {
      type: String,
      default: '30%'
    },
    top: {
      type: String,
      default: '15vh'
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  watch: {
    visible(val) {
      this.dialogVisible = val
    }
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
      this.$emit('close', false)
    }
  }

}
</script>

<style lang="less" scoped>
::v-deep .we-dialog {
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .el-dialog__header {
    background: #4392FD;
    padding: 16px 20px;
    text-align: left;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
    }

    .el-dialog__close {
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 500;
    }
  }

  .el-dialog__body {
    flex-grow: 1;
    padding: 0;
  }

  .dialog-footer {
    .el-button {
      width: 99px;
      height: 40px;
      background-color: #E9F0F9;
      border-color: #E9F0F9;
    }
  }
}
</style>
